<template>
  <div class="flex-grow-1" style="min-height: 400px;">
    <v-tabs
      v-model="selectedTab"
      active-class="tab-active"
      class="mb-4 pa-1"
      hide-slider
      show-arrows
      v-if="currentStateUser === actionStateConstants.DATA_LOADED"
    >
      <v-tab
        v-if="true"
        key="information"
        :disabled="false"
        href="#information"
      >
        Information
      </v-tab>

      <!--      <v-tab-->
      <!--        v-if="true"-->
      <!--        key="catalogues"-->
      <!--        :disabled="false"-->
      <!--        href="#catalogues"-->
      <!--      >-->
      <!--        Catalogue-->
      <!--      </v-tab>-->

      <v-tab
        v-if="$isFeatureAvailable('project-configurations-tab')"
        key="configuration"
        :disabled="false"
        href="#configuration"
      >
        Configurations
      </v-tab>

      <v-tab
        v-if="$isFeatureAvailable('project-background-tab')"
        key="background"
        :disabled="false"
        href="#background"
      >
        Background
      </v-tab>

      <v-tab
        v-if="$isFeatureAvailable('project-grid-tab')"
        key="grid"
        :disabled="false"
        href="#grid"
      >
        Grid
      </v-tab>

      <v-tab
          v-if="$isFeatureAvailable('project-rule-model-tab')"
        key="rule"
        href="#rule"
      >
        Rule Models
      </v-tab>
    </v-tabs>

    <div>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-if="selectedTab === 'information'"
          key="information"
          value="information"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section pa-2"
          >
            <project-single-overview-main-section
              :project-prop.sync="project"
            />
          </div>
        </v-tab-item>

        <v-tab-item
          v-if="selectedTab === 'configuration'"
          key="configuration"
          value="configuration"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section"
          >
            <project-single-overview-main-section-configurations-tab
              :configurations-prop="configurations"
              :project-prop="project"
              @downloadConfigurationSave="downloadConfigurationSave"
            />
          </div>
        </v-tab-item>

        <v-tab-item
          v-if="selectedTab === 'background'"
          key="background"
          value="background"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section"
          >
            <project-single-overview-main-section-background-tab
              :background-models-prop="backgroundModels"
              :is-loading-prop="isBackgroundModelsLoading"
              @exportCurrentView="exportCurrentView"
              @removeBackgroundModel="removeBackgroundModel"
            />
          </div>
        </v-tab-item>

        <v-tab-item
          v-if="selectedTab === 'grid'"
          key="grid"
          value="grid"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section"
          >
            <project-single-overview-main-section-grid-tab
              :grid-model-prop="grid"
              :is-loading-prop="isGridLoading"
              @removeGridModel="removeGridModel"
              @updateCurrentGrid="updateCurrentGrid"
            />
          </div>
        </v-tab-item>

        <v-tab-item
          v-if="selectedTab === 'rule'"
          key="rule"
          value="rule"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section"
          >
            <project-single-overview-main-section-rule-tab
              :is-loading-prop="isRuleLoading"
              :rule-model-prop="rule"
              @removeRuleModel="removeRuleModel"
              @updateCurrentRule="updateCurrentRule"
            />
          </div>
        </v-tab-item>

        <!--        <v-tab-item-->
        <!--          v-if="selectedTab === 'catalogues'"-->
        <!--          key="catalogues"-->
        <!--          value="catalogues"-->
        <!--        >-->
        <!--          <div-->
        <!--            v-if="currentState === actionStateConstants.DATA_LOADED"-->
        <!--            class="tabs-content-section pa-2"-->
        <!--          >-->
        <!--            <project-single-overview-main-section-catalogues-tab :project-catalogues-prop="projectCatalogues" />-->
        <!--          </div>-->
        <!--        </v-tab-item>-->
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { actionStates } from '@/constants'
import { mapGetters } from 'vuex'
import ProjectSingleOverviewMainSection from '@/components/project/single-overview/ProjectSingleOverviewMainSection'
import ProjectSingleOverviewMainSectionConfigurationsTab
  from '@/components/project/single-overview/ProjectSingleOverviewMainSectionConfigurationsTab'
import VueCookies from 'vue-cookies'
import ProjectSingleOverviewMainSectionBackgroundTab
  from '@/components/project/single-overview/ProjectSingleOverviewMainSectionBackgroundTab.vue'
import ProjectService from '@/services/project.service'
import ProjectSingleOverviewMainSectionGridTab
  from '@/components/project/single-overview/ProjectSingleOverviewMainSectionGridTab.vue'
import ProjectSingleOverviewMainSectionRuleTab
  from '@/components/project/single-overview/ProjectSingleOverviewMainSectionRuleTab.vue'

export default {
  name: "ProjectSingleOverviewMainSectionContainer",
  components: {
    ProjectSingleOverviewMainSectionRuleTab,
    ProjectSingleOverviewMainSectionGridTab,
    ProjectSingleOverviewMainSectionBackgroundTab,
    ProjectSingleOverviewMainSectionConfigurationsTab,
    ProjectSingleOverviewMainSection
  },

  data () {
    return {
      actionStateConstants: actionStates,
      isBackgroundModelsLoading: false,
      isGridLoading: false,
      isRuleLoading: false
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'project/getCurrentState',
        currentStateUser: 'user/getCurrentState',
        project: 'project/getSingleProject',
        projectCatalogues: 'project/getSingleProjectCatalogues',
        configurations: 'project/getConfigurations',
        backgroundModels: 'project/getBackgroundModels',
        grid: 'project/getGrid',
        rule: 'project/getRule',
        userSelectedOrganization: 'user/getUserSelectedOrganization',
        organizationSelectedFeatures: 'user/getUserSelectedOrganization'
      }
    ),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.replace({
          query: { ...this.$route.query, tab }
        })
      }
    }
  },

  methods: {
    async downloadConfigurationSave (configurationSaveId) {
      try {
        let token = VueCookies.get('current_user')
        const response = await window.jsSandboxInterface.downloadConfigurationSaveEvent(token, this.userSelectedOrganization.slug, configurationSaveId)
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully download the file',
          duration: 4000,
          mode: 'success'
        })

        if (response != 'Success') {
          throw new Error()
        }
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to download the file',
          duration: 4000,
          mode: 'warning'
        })
      }
    },

    async getProjectBackgroundModels () {
      await this.$store.dispatch('project/fetchSingleProjectBackgroundModels', this.project.id)
    },

    async exportCurrentView () {
      try {
        this.isBackgroundModelsLoading = true
        let token = VueCookies.get('current_user')

        const response = await window.jsSandboxInterface.exportBackgroundEvent(token, this.userSelectedOrganization.slug, this.project.id)
        await this.getProjectBackgroundModels()

        if (response != 'Success') {
          throw new Error()
        }

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Environment is exported.',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'The operation could not be completed.',
          duration: 4000,
          mode: 'warning'
        })
      } finally {
        this.isBackgroundModelsLoading = false
      }
    },

    async removeBackgroundModel (deleteBackgroundModelId) {
      try {
        await ProjectService.deleteProjectBackgroundModel(deleteBackgroundModelId)
        await this.getProjectBackgroundModels()

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'The view is deleted',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'A problem occurred. Please try again.',
          duration: 4000,
          mode: 'warning'
        })
      }
    },

    async getProjectGrid () {
      await this.$store.dispatch('project/fetchSingleProjectGrid', this.project.id)
    },

    async updateCurrentGrid () {
      try {
        this.isGridLoading = true
        let token = VueCookies.get('current_user')

        const response = await window.jsSandboxInterface.exportGridEvent(token, this.userSelectedOrganization.slug, this.project.id)
        await this.getProjectGrid()

        if (response != 'Success') {
          throw new Error()
        }
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Grid is updated!',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'The operation could not be completed.',
          duration: 4000,
          mode: 'warning'
        })
      } finally {
        this.isGridLoading = false
      }
    },

    async removeGridModel (deleteGridId) {
      try {
        await ProjectService.deleteProjectGrid(deleteGridId)
        await this.getProjectGrid()
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Grid is deleted',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'A problem occurred. Please try again.',
          duration: 4000,
          mode: 'warning'
        })
      }
    },

    async getProjectRule () {
      await this.$store.dispatch('project/fetchSingleProjectRule', this.project.id)
    },

    async updateCurrentRule () {
      try {
        this.isRuleLoading = true
        let token = VueCookies.get('current_user')

        const response = await window.jsSandboxInterface.createRuleModelEvent(token, this.userSelectedOrganization.slug, this.project.id)
        await this.getProjectRule()

        if (response != 'Success') {
          throw new Error()
        }

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Rule is updated!',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'The operation could not be completed.',
          duration: 4000,
          mode: 'warning'
        })
      } finally {
        this.isRuleLoading = false
      }
    },

    async removeRuleModel (deleteRuleId) {
      try {
        await ProjectService.deleteProjectRule(deleteRuleId)
        await this.getProjectRule()
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Rule is deleted',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'A problem occurred. Please try again.',
          duration: 4000,
          mode: 'warning'
        })
      }
    }

  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.list-catalogues-categories {
  cursor: pointer;
  user-select: none;
  color: var(--v-grey-base);
  border-radius: $border-radius-root;
  transition: background $secondary-transition,
  border $secondary-transition,
  color $secondary-transition;

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }

  &:hover {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    .v-avatar {
      background: var(--v-primary-base);
    }
  }

  .section-title {
    color: var(--v-grey-darken1);
  }
}
</style>

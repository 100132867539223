<template>
  <div>
    <project-list-all-container />
  </div>
</template>

<script>
import ProjectListAllContainer from '@/components/project/ProjectListAllContainer'

export default {
  name: "AllProjectsView",
  components: { ProjectListAllContainer }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <app-subpage-header :title-prop="'Catalogues'" />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <catalogue-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import CatalogueSingleOverview from '@/components/catalogue/CatalogueSingleOverview'

export default {
  name: "CatalogueSingleView",
  components: {
    CatalogueSingleOverview,
    AppSubpageHeader,
    AppLoaderContent
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'catalogue/getCurrentState'
      }
    )
  },

  methods: {
    async getCatalogueInfo () {
      try {
        await this.$store.dispatch('catalogue/fetchCatalog', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/catalogues')
      }
    }
  },

  mounted () {
    this.getCatalogueInfo()
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="my-4 mx-auto mx-md-4">
    <app-image-holder
      :can-update-prop="true"
      :image-prop="entityItemProp.image"
      :img-cover-prop="true"
    />

    <div class="pt-4 d-flex flex-column  justify-start">
      <div class="mb-4">
        <h2 class="my-1 mx-auto">
        <span v-if="entityItemProp.name" class="entity-fields-text-span">
          <strong>
            {{ entityItemProp.name }}
          </strong>
        </span>

          <span v-else-if="!entityItemProp.name" class="text-h5 entity-fields-text-span">
            Name is missing
        </span>
        </h2>

        <slot name="import-catalog-to-revit" />

      </div>

      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import AppImageHolder from '@/components/app/image/AppImageHolder'
// import AppSvg from '@/components/app/AppSvg'

export default {
  name: "AppSingleItemTopSection",
  components: { AppImageHolder },

  props: {
    entityItemProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.hover-text-effect {
  &:hover {
    color: var(--v-primary-base);
  }
}

.description-text {
  color: var(--v-grey-darken1);
}
</style>

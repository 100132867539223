<template>
  <v-app>
    <div class="background-section d-flex justify-center align-center">
      <v-card class="py-10 px-md-5 px-3" elevation="5" rounded="0" style="min-width: 85%;">
        <v-card-title class="pa-0 mb-5">
          <v-img
            id="app-logo-image"
            :aspect-ratio="3.25"
            :src="getLoginImageURL()"
            alt="Logo"
            class="logo mx-auto"
            contain max-height="60" max-width="200"
          />
        </v-card-title>

        <v-card-subtitle class="mt-0 mb-5 text-body-1 text-center text--disabled ">
          Welcome to the plugin!
        </v-card-subtitle>

        <transition :key="$router.currentRoute.path" mode="out-in" name="scale">
          <router-view></router-view>
        </transition>
      </v-card>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "TheAuthLayoutWrap",

  methods: {
    getLoginImageURL () {
      return require('@/assets/img/sandbox-logo.png')
    }
  }
}
</script>

<style lang="scss" scoped>

.background-section {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/bg-simple.png");
  background-color: var(--v-primary-darken2);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

</style>

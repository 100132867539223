<template>
  <div class="text-center">
    <v-snackbar
      id="snackbar-message"
      v-model="showSnackbar"
      :multi-line="multiLine"
      :timeout="snackbarMessageData.duration"
      class="rounded-lg"
      color="white"
      elevation="12"
      style="margin-bottom: 80px;"
    >

      <v-layout class="rounded-lg">
        <div
          :class="`message-${snackbarMessageData.mode}`"
          class="py-8 px-7"
          style="border-radius: 12px 0 0 12px"
        >
          <app-svg
            :src="`~/assets/img/icons/message-${snackbarMessageData.mode}.svg`"
            color="white"
            size="25"
          />
        </div>

        <div class="pa-4 text--black">
          <h4 class="text-transform-capitalize font-weight-bold mb-1">
            {{ snackbarMessageData.mode }}!
          </h4>

          <p class="mb-0"
             style="color: black">
            {{ snackbarMessageData.message }}
          </p>

          <v-btn
            id="snackbar-close-button"
            class="popup-close-btn"
            icon
            x-small
            @click.native="closeSnackbar()"
          >
            <app-svg
              color="grey"
              size="10"
              src="~/assets/img/icons/close.svg"
            />
          </v-btn>
        </div>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import AppSvg from '@/components/app/AppSvg.vue'

export default {
  name: "AppSnackbar",
  components: { AppSvg },

  data: () => ({
    multiLine: false,
    showSnackbar: false
  }),

  methods: {
    closeSnackbar () {
      this.showSnackbar = false
    }
  },

  computed: {
    ...mapGetters(
      {
        snackbarMessageData: 'snackbar/getSnackbarMessage'
      }
    )
  },

  watch: {
    snackbarMessageData () {
      this.showSnackbar = true
    }
  }
}

</script>

<style lang="scss" scoped>
:deep(.v-snack__content) {
  padding: 0 !important;
}

:deep(.v-snack__wrapper.v-sheet.theme--dark.elevation-12.white) {
  border-radius: 12px !important;
}

.popup-close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.message-error {
  background-color: red;
}

.message-fail {
  background-color: #FFB755;
}

.message-warning {
  background-color: #FFB755;
}

.message-success {
  background-color: #3CC480;
}
</style>

<template>
  <app-dialog
    v-if="isDialogVisible"
    :persistent="true"
    :value="isDialogVisible"
    maxWidth="600px"
    title-prop="Choose an Organization"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="list-body py-3">
      <app-list-item
        v-for="(organization,index) in organizations"
        :key="`organization-${index}`"
        :class="userSelectedOrganization && userSelectedOrganization.id === organization.id ? 'current-organization' : ''"
        class="cursor-pointer"
        hover
        @click.native="chooseOrganization(organization)"
      >
        <div
          class="image-holder d-flex justify-center align-center pa-1"
          style="background: white; height: 60px; width:60px; box-shadow: 1px 1px 2px #00000029; border-radius: 6px;"
        >
          <v-img
            :alt="`${organization.name} image`"
            :src="organization.logo && organization.logo.url"
            contain
          />
        </div>

        <template #title>
          <div>
            <div class="list-item-name fade">{{ organization.name }}</div>
          </div>
        </template>
      </app-list-item>
    </div>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import AppListItem from '@/components/app/AppListItem'
import { mapGetters } from 'vuex'

export default {
  name: "AppChooseOrganizationDialog",
  components: { AppListItem, AppDialog },
  props: {
    organizationsProps: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  computed: {
    ...mapGetters(
      {
        showSelectOrganizationDialog: 'chooseOrganization/getShowSelectOrganizationDialog',
        selectOrganizationDialogKey: 'chooseOrganization/getSelectOrganizationDialogKey',
        organizations: 'user/getAllUserOrganizations',
        userSelectedOrganization: 'user/getUserSelectedOrganization'
      }
    ),

    isDialogVisible: {
      get () {
        return this.showSelectOrganizationDialog
      },
      set (value) {
        this.$store.dispatch('chooseOrganization/toggleSelectOrganizationDialog', value)
      }
    }
  },

  methods: {
    chooseOrganization (organization) {
      window.location.href = `${ window.location.protocol }//${ organization.slug }.${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }:${ window.location.port }/`
      this.isDialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.list-body {
  background-color: #c3c3c31a;
}

.list-item-name {
  width: 55vw;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (min-width: 630px) {
    width: 490px;
  }
}

.fade {
  mask-image: linear-gradient(90deg, #000000 80%, transparent);
  -webkit-mask-image: linear-gradient(90deg, #000000 80%, transparent);
}

.current-organization {
  background-color: var(--v-primary-lighten2)
}
</style>

import $axios from '../plugins/axios'

class CatalogueService {
  getAllCatalogues () {
    return $axios.get(`/get-catalogues`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleCatalog (catalogId) {
    return $axios.get(`/get-catalogue/${ catalogId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getCatalogueCategoriesModules (catalogId) {
    return $axios.get(`/get-catalogue-categories/${ catalogId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  downloadOneCatalogueModule (moduleId) {
    return $axios.get(`/get-module-revit-files/${ moduleId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  downloadAllCatalogueModules (catalogId) {
    return $axios.get(`/get-catalogue-modules-revit-files/${ catalogId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new CatalogueService()

<template>
  <div>
    <catalogue-list-all
      :catalogues-prop="cataloguesFromStore"
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import CatalogueListAll from '@/components/catalogue/CatalogueListAll'
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "CatalogueListAllContainer",
  components: { CatalogueListAll },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        cataloguesFromStore: 'catalogue/getCatalogues',
        currentState: 'catalogue/getCurrentState'
      }
    )
  },

  created () {
    let slug = window.location.hostname.split(`${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }`)[0]

    if (slug) {
      this.$store.dispatch('catalogue/fetchAllCatalogues')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="flex-grow-1" style="min-height: 400px;">
    <v-tabs
      v-model="selectedTab"
      active-class="tab-active"
      class="mb-4 pa-1"
      hide-slider
      show-arrows
    >
      <v-tab
        v-if="true"
        key="information"
        :disabled="false"
        href="#information"
      >
        Information
      </v-tab>

      <v-tab
        v-if="true"
        key="modules"
        :disabled="false"
        href="#modules"
      >
        Modules
      </v-tab>
    </v-tabs>

    <div>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-if="selectedTab === 'information'"
          key="information"
          value="information"
        >
          <div
            v-if="currentState === actionStateConstants.DATA_LOADED"
            class="tabs-content-section pa-2"
          >
            <catalogue-single-overview-main-section
              :catalogue-prop.sync="catalogue"
            />
          </div>
        </v-tab-item>

        <v-tab-item
          v-if="selectedTab === 'modules'"
          key="modules"
          value="modules"
        >
          <div v-if="currentState === actionStateConstants.DATA_LOADED">
            <catalogue-single-overview-main-section-module-tab
              v-if="catalogueModules"
              :catalogue-modules-prop="catalogueModules"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { actionStates } from '@/constants'
import { mapGetters } from 'vuex'
import CatalogueSingleOverviewMainSection
  from '@/components/catalogue/single-overview/CatalogueSingleOverviewMainSection'
import CatalogueSingleOverviewMainSectionModuleTab
  from '@/components/catalogue/single-overview/CatalogueSingleOverviewMainSectionModulesTab'

export default {
  name: "CatalogueSingleOverviewMainSectionContainer",
  components: {
    CatalogueSingleOverviewMainSectionModuleTab,
    CatalogueSingleOverviewMainSection
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'catalogue/getCurrentState',
        catalogue: 'catalogue/getSingleCatalogue',
        catalogueModules: 'catalogue/getSingleCatalogueModules'
      }
    ),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.replace({
          query: { ...this.$route.query, tab }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

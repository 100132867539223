import { actionStates } from '@/constants'
import CatalogueService from '@/services/catalogue.service'

const state = {
  currentState: actionStates.INITIAL,
  catalogues: [],
  catalogue: null,
  catalogueModules: null
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getCatalogues (state) {
    return state.catalogues
  },

  getSingleCatalogue (state) {
    return state.catalogue
  },

  getSingleCatalogueModules (state) {
    return state.catalogueModules
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setCatalogues (state, catalogues) {
    if (catalogues) {
      state.catalogues = catalogues
      return
    }

    state.catalogues = null
  },

  setSingleCatalogue (state, catalogue) {
    if (catalogue) {
      state.catalogue = catalogue
      return
    }

    state.catalogue = null
  },

  setSingleCatalogueModules (state, catalogueModules) {
    if (catalogueModules) {
      state.catalogueModules = catalogueModules
      return
    }

    state.catalogueModules = null
  }
}

const actions = {
  async fetchAllCatalogues (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let catalogues = await CatalogueService.getAllCatalogues()

      vuexContext.commit('setCatalogues', catalogues)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
        return
      }

      vuexContext.commit('setActionState', actionStates.ERRORED)
    }
  },

  async fetchCatalogueModules (vuexContext, id) {
    let response = await CatalogueService.getCatalogueCategoriesModules(id)
    let modules = response.categories.filter((oneCategory) => oneCategory.modules.length > 0)

    vuexContext.commit('setSingleCatalogueModules', modules)
  },

  async fetchCatalog (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await CatalogueService.getSingleCatalog(id)
      vuexContext.commit('setSingleCatalogue', response)

      await vuexContext.dispatch('fetchCatalogueModules', id)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
        return
      }

      vuexContext.commit('setActionState', actionStates.ERRORED)
    }
  },

  async downloadOneCatalogueModule (vuexContext, moduleId) {
    try {
      let response = await CatalogueService.downloadOneCatalogueModule(moduleId)
      await window.jsSandboxInterface.downloadModuleEvent(JSON.stringify(response))
      await vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: 'Successfully download',
        duration: 4000,
        mode: 'success'
      }, { root: true })
    } catch (error) {
      await vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: 'Failed to download',
        duration: 4000,
        mode: 'warning'
      }, { root: true })
    }
  },

  async downloadAllCatalogueModules (vuexContext, catalogueId) {
    try {
      let response = await CatalogueService.downloadAllCatalogueModules(catalogueId)
      await window.jsSandboxInterface.downloadAllModulesEvent(JSON.stringify(response))
      await vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: 'Successfully download',
        duration: 4000,
        mode: 'success'
      }, { root: true })
    } catch (error) {
      await vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: 'Failed to download',
        duration: 4000,
        mode: 'warning'
      }, { root: true })
    }
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

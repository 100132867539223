import $axios from '../plugins/axios'

class ProjectService {
  getAllProjects () {
    return $axios.get(`/get-projects`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleProject (projectId) {
    return $axios.get(`/get-project/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectConfigurations (projectId) {
    return $axios.get(`/get-project-configurations/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectBackgroundModels (projectId) {
    return $axios.get(`/get-project-background-models/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteProjectBackgroundModel (modelId) {
    return $axios.post(`/delete-background-model`, { id: modelId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectGridModel (projectId) {
    return $axios.get(`/get-project-grid-model/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteProjectGrid (gridId) {
    return $axios.post(`/delete-grid-model`, { id: gridId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProjectRuleModel (projectId) {
    return $axios.get(`/get-project-rule-model/${ projectId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteProjectRule (ruleId) {
    return $axios.post(`/delete-rule-model`, { id: ruleId })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  // getProjectCatalogues (projectId){
  //   return $axios.get(`/get-project-catalogues/${ projectId }`)
  //     .then((res) => {
  //       return res.data
  //     })
  //     .catch((error) => {
  //       throw error
  //     })
  // }
}

export default new ProjectService()

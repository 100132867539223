import VueCookies from 'vue-cookies'

export default function auth ({ next, nextMiddleware }) {
  let isAuthenticated = VueCookies.get('current_user') != null

  if (!isAuthenticated) {
    return next({
      path: '/login'
    })
  }

  return nextMiddleware()
}

<template>
  <div class="ma-2 ">
    <div
      v-if="ruleModelProp"
      class="mb-6 rule-model-container"
    >
      <div>
        <div class="app-list-item hover">
          <div class="d-flex flex-column">
            <v-img
              :alt="`${ruleModelProp.name} picture`"
              :src="ruleModelProp.image && ruleModelProp.image.url"
              class="image-border rule-image-container my-2"
              height="100px"
              style="border-radius: 6px; flex: 0 0 auto;"
            />

            <div class="content-section content-title">
              <div style="margin-right: auto;">
                <div class="d-flex flex-column text-subtitle-1 grey--text text--darken-1">
                  <span v-if="ruleModelProp.name" class="list-item-name export-name-text">
                    <b> {{ ruleModelProp.name }} </b>
                  </span>

                  <span v-if="ruleModelProp.created_at" class="list-item-name exported-on-text">
                    <b>Exported on: </b>{{ ruleModelProp.created_at }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-center align-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                :class="hover ? 'red--text' : 'primary--text' "
                height="30px"
                rounded
                small
                text
                width="150px"
                @click="openDeleteDialog(ruleModelProp.id)"
              >
                <div class="guttered-row">
                  <app-svg
                    :color="hover ? 'red' : 'primary' "
                    class="mr-2"
                    src="@/assets/img/icons/delete.svg"
                    width="16"
                  />
                  Remove Rule models
                </div>
              </v-btn>
            </v-hover>

          </div>
        </div>
      </div>
    </div>

    <v-divider v-if="ruleModelProp > 0" class="mt-7" />

    <div class="d-flex justify-center align-center export-view-section">
      <v-hover v-slot="{ hover }">
        <v-btn
          :class="hover ? 'primary white--text' : 'lighten-2 primary--text' "
          :disabled="isLoadingProp"
          :loading="isLoadingProp"
          :style="ruleModelProp > 0 ? 'margin: 20px;' : 'margin: 60px;'"
          class="export-current-view-button"
          outlined
          rounded
          small
          @click.native="$emit('updateCurrentRule')"
        >
          Export Rule Models
        </v-btn>
      </v-hover>
    </div>

    <project-single-overview-delete-rule-dialog
      :key="deleteDialogKey"
      v-model="isDeleteDialogOpen"
      :is-loading-prop="isDeleteLoading"
      @removeRuleModel="removeRuleModel"
    />

  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg.vue'
import ProjectSingleOverviewDeleteRuleDialog
  from '@/components/project/single-overview/ProjectSingleOverviewDeleteRuleDialog.vue'

export default {
  name: 'ProjectSingleOverviewMainSectionRuleTab',
  components: { ProjectSingleOverviewDeleteRuleDialog, AppSvg },
  props: {
    ruleModelProp: {
      type: Object,
      default: () => {
      }
    },

    isLoadingProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDeleteDialogOpen: false,
      deleteDialogKey: 0,
      deleteRuleModelId: null,
      isDeleteLoading: false
    }
  },

  methods: {
    openDeleteDialog (ruleId) {
      this.isDeleteDialogOpen = true
      this.deleteDialogKey++
      this.deleteRuleModelId = ruleId
    },

    closeDeleteDialog () {
      this.isDeleteDialogOpen = false
      this.deleteRuleModelId = null
      this.isDeleteLoading = false
    },

    async removeRuleModel () {
      this.isDeleteLoading = true
      this.$emit('removeRuleModel', this.deleteRuleModelId)
      this.closeDeleteDialog()
    }

  }
}
</script>

<style lang="scss" scoped>
.export-view-section .theme--light.v-btn {
  background-color: var(--v-primary-lighten2);
}
</style>

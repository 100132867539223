<template>
  <div>
    <project-single-overview-top-section v-if="project" :project-prop.sync="project" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ProjectSingleOverviewTopSection from '@/components/project/single-overview/ProjectSingleOverviewTopSection'

export default {
  name: "ProjectSingleOverviewTopSectionContainer",
  components: {
    ProjectSingleOverviewTopSection
  },

  computed: {
    ...mapGetters(
      {
        project: 'project/getSingleProject'
      }
    )
  }
}
</script>

<style scoped>

</style>

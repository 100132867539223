<template>
  <div>
    <project-list-all
      :projects-prop="projectsFromStore"
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import ProjectListAll from '@/components/project/ProjectListAll'
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "ProjectListAllContainer",
  components: { ProjectListAll },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        projectsFromStore: 'project/getProjects',
        currentState: 'project/getCurrentState'
      }
    )
  },

  created () {
    let slug = window.location.hostname.split(`${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }`)[0]

    if (slug) {
      this.$store.dispatch('project/fetchAllProjects')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="text-regular">
      <div>
        <div class="entity-fields-text-span font-weight-bold">
          Description:
        </div>

        <div class="description-field" style="white-space: pre-wrap; word-break: break-word;">
          <template v-if="catalogueProp && catalogueProp.description">{{ catalogueProp.description }}</template>
        </div>
      </div>
    </div>

    <!--    <div class="text-regular text-justify pt-8 pb-5">-->
    <!--      <div>-->
    <!--        <span class="entity-fields-text-span">-->
    <!--          <b> Manufacturer: </b>{{ catalogueProp.manufacturer }}-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="text-regular text-justify pt-3">
      <div>
        <span class="entity-fields-text-span updated-at-field">
          <b> Last Updated: </b>
          <template v-if="catalogueProp && catalogueProp.updated_at">{{ catalogueProp.updated_at }}</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CatalogueSingleOverviewMainSection",

  props: {
    catalogueProp: {
      type: Object,
      default () {
        return {
          description: '',
          updated_at: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.catalogue-name {
  color: var(--v-grey-base)
}

</style>

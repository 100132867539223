<template>
  <div>
    <v-expansion-panels
      v-for="(category, index) in catalogueModulesProp"
      :key="`category-${category.id}-${index}`"
      accordion
      flat
      focusable
      hover
      multiple
      tile
    >
      <app-expansion-panel fit>
        <template #header>
          <h5 class="text-h5 py-0">
            {{ category.name }}
          </h5>
        </template>

        <div
          v-for="(module, index) in category.modules"
          :key="`module-${module.id}-${index}`"
          class="px-2"
        >
          <catalogue-module-list-item :module-prop="module" />
        </div>
      </app-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import CatalogueModuleListItem from '@/components/catalogue/CatalogueModuleListItem'

export default {
  name: "CatalogueSingleOverviewMainSectionModulesTab",
  components: {
    CatalogueModuleListItem,
    AppExpansionPanel
  },
  props: {
    catalogueModulesProp: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.catalogue-name {
  color: var(--v-grey-base)
}

</style>

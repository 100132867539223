<template>
  <div class="ma-2 ">
    <div
      v-for="(singleBackgroundModel, index) in backgroundModelsProp"
      :key="`background-model-${singleBackgroundModel.id}-${index}`"
      class="mb-6 background-models-container"
    >
      <div>
        <div class="app-list-item hover">
          <div class="d-flex flex-column">
            <v-img
              :alt="`${singleBackgroundModel.name} picture`"
              :src="singleBackgroundModel.image && singleBackgroundModel.image.url"
              class="image-border background-image-container my-2"
              height="100px"
              style="border-radius: 6px; flex: 0 0 auto;"
            />

            <div class="content-section content-title">
              <div style="margin-right: auto;">
                <div class="d-flex flex-column text-subtitle-1 grey--text text--darken-1">
                  <span v-if="singleBackgroundModel.name" class="list-item-name export-name-text">
                    <b> {{ singleBackgroundModel.name }} </b>
                  </span>

                  <span v-if="singleBackgroundModel.created_at" class="list-item-name exported-on-text">
                    <b>Exported on: </b>{{ singleBackgroundModel.created_at }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-center align-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                :class="hover ? 'red--text' : 'primary--text' "
                height="30px"
                rounded
                small
                text
                width="150px"
                @click="openDeleteDialog(singleBackgroundModel.id)"
              >
                <div class="guttered-row">
                  <app-svg
                    :color="hover ? 'red' : 'primary' "
                    class="mr-2"
                    src="@/assets/img/icons/delete.svg"
                    width="16"
                  />
                  Remove this view
                </div>
              </v-btn>
            </v-hover>

          </div>
        </div>
      </div>
    </div>

    <v-divider v-if="backgroundModelsProp && backgroundModelsProp.length > 0" class="mt-7" />

    <div class="d-flex justify-center align-center export-view-section">
      <v-hover v-slot="{ hover }">
        <v-btn
          :class="hover ? 'primary white--text' : 'lighten-2 primary--text' "
          :disabled="isLoadingProp"
          :loading="isLoadingProp"
          :style="backgroundModelsProp && backgroundModelsProp.length > 0 ? 'margin: 20px;' : 'margin: 60px;'"
          class="export-current-view-button"
          outlined
          rounded
          small
          @click.native="$emit('exportCurrentView')"
        >
          Export Current View
        </v-btn>
      </v-hover>
    </div>

    <project-single-overview-delete-background-dialog
      :key="deleteDialogKey"
      v-model="isDeleteDialogOpen"
      :is-loading-prop="isDeleteLoading"
      @removeBackground="removeBackgroundModel"
    />

  </div>
</template>

<script>
import ProjectSingleOverviewDeleteBackgroundDialog
  from '@/components/project/single-overview/ProjectSingleOverviewDeleteBackgroundDialog.vue'
import AppSvg from '@/components/app/AppSvg.vue'

export default {
  name: 'ProjectSingleOverviewMainSectionBackgroundTab',
  components: { AppSvg, ProjectSingleOverviewDeleteBackgroundDialog },
  props: {
    backgroundModelsProp: {
      type: Array,
      default: () => []
    },

    isLoadingProp: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDeleteDialogOpen: false,
      deleteDialogKey: 0,
      deleteBackgroundModelId: null,
      isDeleteLoading: false
    }
  },

  methods: {
    openDeleteDialog (backgroundId) {
      this.isDeleteDialogOpen = true
      this.deleteDialogKey++
      this.deleteBackgroundModelId = backgroundId
    },

    closeDeleteDialog () {
      this.isDeleteDialogOpen = false
      this.deleteBackgroundModelId = null
      this.isDeleteLoading = false
    },

    async removeBackgroundModel () {
      this.isDeleteLoading = true
      this.$emit('removeBackgroundModel', this.deleteBackgroundModelId)
      this.closeDeleteDialog()
    }

  }
}
</script>

<style lang="scss" scoped>
.export-view-section .theme--light.v-btn {
  background-color: var(--v-primary-lighten2);
}
</style>

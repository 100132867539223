import $axios from '../plugins/axios'

const baseOmtApiURL = `${ location.protocol }//${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

class OrganizationService {
  getAllOrganizations () {
    return $axios.get(`${ baseOmtApiURL }/get-organizations`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getUserProductOrganizations (productId) {
    return $axios.get(`${ baseOmtApiURL }/get-user-product-organizations/${ productId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganizationSelectedFeatures ({ productId, slug }) {
    const baseOmtURLWithSlug = `${ location.protocol }//${ slug }${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

    return $axios.get(`${ baseOmtURLWithSlug }/get-organization-selected-features/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new OrganizationService()

<template>
  <div>
    <app-single-item-top-section
      v-if="moduleProp"
      :entity-item-prop="moduleProp"
    >
      <template #actions>

        <div class="revit-actions-wrapper">
          <v-hover v-slot="{ hover }">
            <v-btn
              :class="hover ? 'primary white--text' : 'primary lighten-2 primary--text' "
              id="module-import-to-revit-btn"
              height="32px"
              rounded
              small
              text
              @click="$emit('importModuleToRevit')"
            >
              <app-svg
                  class="mr-2"
                  size="16"
                  src="~/assets/img/icons/icon-import.svg"
              />
              Import to Revit
            </v-btn>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <v-btn
              :class="hover ? 'primary white--text' : 'primary lighten-2 primary--text' "
              id="module-upload-btn"
              height="32px"
              rounded
              small
              text
              @click.native="$emit('uploadModule', moduleProp.id)"
            >
              <app-svg
                  class="mr-2"
                  size="16"
                  src="~/assets/img/icons/icon-update.svg"
              />
              Update Module
            </v-btn>
          </v-hover>
        </div>

      </template>
    </app-single-item-top-section>
  </div>
</template>

<script>
import AppSingleItemTopSection from '@/components/app/AppSingleItemTopSection'
import AppSvg from '@/components/app/AppSvg'

export default {
  name: "ModuleSingleOverviewTopSection",
  components: {
    AppSvg,
    AppSingleItemTopSection
  },

  props: {
    moduleProp: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.module-name {
  color: var(--v-grey-base)
}

.revit-actions-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px
}

@media  (max-width: 340px) {
  .revit-actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

</style>

<template>
  <div>
    <div class="text-regular text-justify pt-3">
      <div>
        <span class="entity-fields-text-span">
            <v-img
              v-if="projectProp.logo && projectProp.logo.url"
              id="project-logo"
              :src="projectProp.logo.url"
              contain
              max-height="20"
              max-width="80"
              style="cursor:pointer; max-width:80px;"
              v-bind="$attrs"
              v-on="$listeners"
            />
        </span>
      </div>
    </div>
    <v-divider class="my-2" />

    <div class="text-regular">
      <div>
        <div class="entity-fields-text-span font-weight-bold">
          Description:
        </div>

        <div id="project-description-text" style="white-space: pre-wrap; word-break: break-word;">
          <template v-if="projectProp && projectProp.description">{{ projectProp.description }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProjectSingleOverviewMainSection",

  props: {
    projectProp: {
      type: Object,
      default () {
        return {
          description: null,
          logo: {
            url: null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.project-name {
  color: var(--v-grey-base)
}

</style>

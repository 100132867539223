<template>
  <div>
    <v-layout class="align-center mb-10">
      <h2 class="text-h2">
        Modules
      </h2>

<!--      <v-spacer />-->

      <app-data-sort-by
        class="mx-1"
        :sort-options-prop="sortOptions"
        @changeSort="makeSort"

      />

      <v-hover v-slot="{ hover }">
        <v-btn
          :class="hover ? 'primary white--text' : 'primary lighten-2 primary--text' "
          height="24px"
          rounded
          small
          text
          @click="createNewModule"
        >
          Create new
        </v-btn>
      </v-hover>
    </v-layout>

    <list-overview
      :can-create=false
      :cols-prop="{ cols: 6, xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }"
      :data-source="modulesProp"
      :is-list-loading-prop="isListLoadingProp"
      :selectable="false"
      list-type="module"
      target-url="/modules"
    >
      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :item-type-prop="'module'"
          :selectable="selectable"
          :target="target"
        />
      </template>
    </list-overview>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'
import AppDataSortBy from '@/components/app/data/AppDataSortBy.vue'

import { mapGetters } from 'vuex'
import VueCookies from 'vue-cookies'

import { v4 as uuidv4 } from "uuid"

export default {
  name: "ModuleListAll",
  components: { ListItemCommon, ListOverview, AppDataSortBy },
  props: {
    modulesProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Updated - [A-Z]', key: 'updatedAt', direction: 'acs',  id: uuidv4() },
        { text: 'Date Updated - [Z-A]', key: 'updatedAt', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'updatedAt', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
          }
        },

        { key: 'updatedAt', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters(
      {
        userSelectedOrganization: 'user/getUserSelectedOrganization'
      }
    )
  },

  methods: {
    async createNewModule () {
      let token = VueCookies.get('current_user')
      await window.jsSandboxInterface.createModuleEvent(token, this.userSelectedOrganization.slug)
      await this.$store.dispatch('module/fetchAllModules')
    },

    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.modulesProp)
      }
    }
  }
}
</script>

<style scoped>

</style>

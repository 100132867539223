<template>
  <div>
    <the-default-layout-wrap>
      <router-view></router-view>
    </the-default-layout-wrap>
  </div>
</template>

<script>
import TheDefaultLayoutWrap from "./layouts/TheDefaultLayoutWrap.vue"

export default {
  name: 'AppLayoutContent',
  components: {
    TheDefaultLayoutWrap
  },

  methods: {
    setDefaultAPIURL (slug) {
      this.$axios.defaults.baseURL = `${ window.location.protocol }//${ slug }${ process.env.VUE_APP_API_VCSB_BASE_URL }/api/v1`
    }
  },

  async created () {
    let slug = window.location.hostname.split(`${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }`)[0]

    if (!slug) {
      await this.$store.dispatch('user/fetchAllOrganizations')
      await this.$store.dispatch('chooseOrganization/showSelectOrganizationDialog')
      return
    }

    await this.setDefaultAPIURL(slug)
    await this.$store.dispatch('user/fetchUserOrganizationIfMissing', slug)
    await this.$store.dispatch('auth/fetchUserIfMissing')
  }
}
</script>

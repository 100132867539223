<template>
  <div>
    <!--    <div class="text-regular text-justify">-->
    <!--      <div>-->
    <!--        <span class="entity-fields-text-span">-->
    <!--          <b> Bounding box: </b> {{ moduleProp.width }} x {{ moduleProp.height }} x {{ moduleProp.depth }} m-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="text-regular text-justify pt-8 pb-5">-->
    <!--      <div>-->
    <!--        <span class="entity-fields-text-span">-->
    <!--          <b> Manufacturer: </b>{{ moduleProp.manufacturer }}-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="text-regular text-justify">
      <div>
        <span class="entity-fields-text-span last-updated-field">
          <b> Last Updated: </b>
          <template v-if="moduleProp && moduleProp.updated_at">{{ $timeToLocal(moduleProp.updated_at)}}</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModuleSingleOverviewMainSection",

  props: {
    moduleProp: {
      type: Object,
      default () {
        return {
          width: '',
          height: '',
          depth: '',
          updated_at: ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.module-name {
  color: var(--v-grey-base)
}

</style>

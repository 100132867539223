<template>
  <div>
    <module-list-all
      :is-list-loading-prop="currentState === actionStateConstants.LOADING"
      :modules-prop="modulesFromStore"
    />
  </div>
</template>

<script>
import ModuleListAll from '@/components/module/ModuleListAll'
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'

export default {
  name: "ModuleListAllContainer",
  components: { ModuleListAll },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        modulesFromStore: 'module/getModules',
        currentState: 'module/getCurrentState'
      }
    )
  },

  created () {
    let slug = window.location.hostname.split(`${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }`)[0]

    if (slug) {
      this.$store.dispatch('module/fetchAllModules')
    }
  },

  // mounted () {
  //   this.gettingModulesInterval = window.setInterval(async () => {
  //     await this.$store.dispatch('module/fetchAllModules')
  //   }, 5000)
  // },

  // beforeDestroy () {
  //   window.clearInterval(this.gettingModulesInterval)
  // }
}
</script>

<style scoped>

</style>

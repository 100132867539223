<template>
  <div>
    <the-auth-layout-wrap>
      <router-view></router-view>
    </the-auth-layout-wrap>
  </div>
</template>

<script>
import TheAuthLayoutWrap from "./layouts/TheAuthLayoutWrap.vue"

export default {
  name: 'AppAuthLayoutContent',
  components: {
    TheAuthLayoutWrap
  }

}
</script>


<style lang="scss" scoped>


</style>

<template>
  <div>
    <catalogue-single-overview-top-section
      :catalogue-prop.sync="catalogue"
      @importCatalogToRevit="importCatalogToRevit"
    />
  </div>
</template>

<script>

import CatalogueSingleOverviewTopSection from '@/components/catalogue/single-overview/CatalogueSingleOverviewTopSection'

import { mapGetters } from 'vuex'

export default {
  name: "CatalogueSingleOverviewTopSectionContainer",
  components: {
    CatalogueSingleOverviewTopSection
  },

  computed: {
    ...mapGetters(
      {
        catalogue: 'catalogue/getSingleCatalogue'
      }
    )
  },

  methods: {
    importCatalogToRevit () {
      this.$store.dispatch('catalogue/downloadAllCatalogueModules', this.catalogue.id)
    }
  }
}
</script>

<style scoped>

</style>

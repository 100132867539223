import { actionStates } from '@/constants'
import ModuleService from '@/services/module.service'

const state = {
  currentState: actionStates.INITIAL,
  modules: [],
  module: null
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getModules (state) {
    return state.modules
  },

  getSingleModule (state) {
    return state.module
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setModules (state, modules) {
    if (modules) {
      state.modules = modules
      return
    }

    state.modules = null
  },

  setSingleModule (state, module) {
    if (module) {
      state.module = module
      return
    }

    state.module = null
  }
}

const actions = {
  async fetchAllModules (vuexContext) {
    if (vuexContext.state.modules.length === 0) {
      vuexContext.commit('setActionState', actionStates.LOADING)
    }

    try {
      let modules = await ModuleService.getAllModules()

      vuexContext.commit('setModules', modules)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
      throw e
    }
  },

  async fetchSingleModule (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ModuleService.getSingleModule(id)

      vuexContext.commit('setSingleModule', response)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

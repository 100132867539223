<template>
  <div
    class="content-wrapper pa-4"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-layout class="flex-column flex-xl-row">
      <div class="my-4">
        <v-skeleton-loader min-width="250px" type="card" />

        <v-skeleton-loader class="mt-8" type="paragraph" />
      </div>

      <div class="flex-grow-1">
        <v-skeleton-loader type="table" />
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "AppLoaderContent"
}
</script>

import { actionStates } from '@/constants'
import ProjectService from '@/services/project.service'

const state = {
  currentState: actionStates.INITIAL,
  projects: [],
  project: null,
  projectCatalogues: null,
  configurations: null,
  backgroundModels: null,
  grid: null,
  rule: null
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getProjects (state) {
    return state.projects
  },

  getSingleProject (state) {
    return state.project
  },

  getSingleProjectCatalogues (state) {
    return state.projectCatalogues
  },

  getConfigurations (state) {
    return state.configurations.filter(item => !item.deleted_at)
  },

  getBackgroundModels (state) {
    return state.backgroundModels
  },

  getGrid (state) {
    return state.grid
  },

  getRule (state) {
    return state.rule
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProjects (state, projects) {
    if (projects) {
      state.projects = projects
      return
    }

    state.projects = null
  },

  setSingleProject (state, project) {
    if (project) {
      state.project = project
      return
    }

    state.project = null
  },

  setSingleProjectCatalogues (state, projectCatalogues) {
    if (projectCatalogues) {
      state.projectCatalogues = projectCatalogues
      return
    }

    state.projectCatalogues = null
  },

  setConfigurations (state, configurations) {
    if (configurations) {
      state.configurations = configurations
      return
    }

    state.configurations = null
  },

  setBackgroundModels (state, backgroundModels) {
    if (backgroundModels) {
      state.backgroundModels = backgroundModels
      return
    }

    state.backgroundModels = null
  },

  setGrid (state, grid) {
    if (grid) {
      state.grid = grid
      return
    }

    state.grid = null
  },

  setRule (state, rule) {
    if (rule) {
      state.rule = rule
      return
    }

    state.rule = null
  }
}

const actions = {
  async fetchAllProjects (vuexContext) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let projects = await ProjectService.getAllProjects()

      vuexContext.commit('setProjects', projects)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
        return
      }

      vuexContext.commit('setActionState', actionStates.ERRORED)
    }
  },

  async fetchSingleProject (vuexContext, id) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      let response = await ProjectService.getSingleProject(id)
      vuexContext.commit('setSingleProject', response)

      let responseConfigurations = await ProjectService.getProjectConfigurations(id)
      vuexContext.commit('setConfigurations', responseConfigurations)

      let responseBackgroundModels = await ProjectService.getProjectBackgroundModels(id)
      vuexContext.commit('setBackgroundModels', responseBackgroundModels)

      let responseGridModel = await ProjectService.getProjectGridModel(id)
      vuexContext.commit('setGrid', responseGridModel)

      let responseRuleModel = await ProjectService.getProjectRuleModel(id)
      vuexContext.commit('setRule', responseRuleModel)

      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
        return
      }

      vuexContext.commit('setActionState', actionStates.ERRORED)
    }
  },

  async fetchSingleProjectBackgroundModels (vuexContext, id) {
    let responseBackgroundModels = await ProjectService.getProjectBackgroundModels(id)
    vuexContext.commit('setBackgroundModels', responseBackgroundModels)
  },

  async fetchSingleProjectGrid (vuexContext, id) {
    let responseGrid = await ProjectService.getProjectGridModel(id)
    vuexContext.commit('setGrid', responseGrid)
  },

  async fetchSingleProjectRule (vuexContext, id) {
    let responseRule = await ProjectService.getProjectRuleModel(id)
    vuexContext.commit('setRule', responseRule)
  }
  // async fetchProjectCatalogues (vuexContext, id) {
  //   let response = await ProjectService.getProjectCatalogues(id)
  //   vuexContext.commit('setSingleProjectCatalogues', response)
  // },
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import $axios from '../plugins/axios'

class ConfigurationService {
  getConfigurationSaveDbaTextFile (configurationSaveId) {
    return $axios.get(`/get-configuration-save-dba-text/${ configurationSaveId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ConfigurationService()

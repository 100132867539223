<template>
  <v-app id="app">
    <router-view />

    <app-snackbar />
    <app-choose-organization-dialog />
  </v-app>
</template>

<script>

import AppSnackbar from '@/components/app/AppSnackbar'
import AppChooseOrganizationDialog from '@/components/app/AppChooseOrganizationDialog'

export default {
  name: 'App',
  components: { AppChooseOrganizationDialog, AppSnackbar }

}
</script>

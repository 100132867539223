<template>
  <v-app dark>
    <the-default-layout-wrap-app-bar />

    <v-main class="pt-7" style="margin-bottom: 70px;">
      <transition mode="out-in" name="scale">
        <v-container :key="$router.currentRoute.path" class="pt-0 pb-0" fluid>
          <router-view></router-view>
        </v-container>
      </transition>
    </v-main>

    <the-default-layout-wrap-mobile-footer-menu :items-prop="menuItems" />
  </v-app>
</template>

<script>
import TheDefaultLayoutWrapAppBar from '@/layouts/TheDefaultLayoutWrapAppBar'
import TheDefaultLayoutWrapMobileFooterMenu from '@/layouts/TheDefaultLayoutWrapMobileFooterMenu'

export default {
  name: 'TheDefaultLayoutWrap',
  components: {
    TheDefaultLayoutWrapAppBar,
    TheDefaultLayoutWrapMobileFooterMenu
  },

  data () {
    return {
      menuItems: [
        {
          title: 'Catalogues',
          to: '/catalogues',
          class: 'nav-item-catalogs'
        },
        {
          title: 'Modules',
          to: '/modules',
          class: 'nav-item-modules'
        },
        {
          title: 'Projects',
          to: '/projects',
          class: 'nav-item-projects'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

</style>

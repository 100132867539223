<template>
  <div>
    <app-single-item-top-section
      v-if="catalogueProp"
      :entity-item-prop="catalogueProp"
      >

      <template #import-catalog-to-revit>
        <v-hover>
          <template v-slot="{ hover }">
            <v-btn
                id="import-catalog-to-revit"
                :class="hover ? 'primary white--text' : 'primary lighten-2 primary--text' "
                height="32px"
                rounded
                small
                text
                @click.native="$emit('importCatalogToRevit')"
            >
              <app-svg
                  class="mr-2"
                  size="16"
                  src="~/assets/img/icons/icon-import.svg"
              />
              Import to Revit
            </v-btn>
          </template>
        </v-hover>
      </template>
    </app-single-item-top-section>
  </div>
</template>

<script>
import AppSingleItemTopSection from '@/components/app/AppSingleItemTopSection'
import AppSvg from "@/components/app/AppSvg.vue";

export default {
  name: "CatalogueSingleOverviewTopSection",
  components: {
    AppSingleItemTopSection,
    AppSvg
  },

  props: {
    catalogueProp: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.catalogue-name {
  color: var(--v-grey-base)
}

</style>

<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-xl-row">
      <project-single-overview-top-section-container />

      <project-single-overview-main-section-container />
    </v-layout>
  </div>
</template>

<script>

import ProjectSingleOverviewMainSectionContainer
  from '@/components/project/single-overview/ProjectSingleOverviewMainSectionContainer'
import ProjectSingleOverviewTopSectionContainer
  from '@/components/project/single-overview/ProjectSingleOverviewTopSectionContainer'

export default {
  name: "ProjectSingleOverview",
  components: {
    ProjectSingleOverviewTopSectionContainer,
    ProjectSingleOverviewMainSectionContainer
  }
}
</script>

<style scoped>

</style>

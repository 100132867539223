<template>
  <div>
    <module-list-all-container />
  </div>
</template>

<script>
import ModuleListAllContainer from '@/components/module/ModuleListAllContainer'

export default {
  name: "AllModulesView",
  components: { ModuleListAllContainer }
}
</script>

<style scoped>

</style>

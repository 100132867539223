<template>
  <div>
    <app-single-item-top-section
      v-if="projectProp"
      :entity-item-prop="projectProp"
    />
  </div>
</template>

<script>
import AppSingleItemTopSection from '@/components/app/AppSingleItemTopSection'

export default {
  name: "ProjectSingleOverviewTopSection",
  components: {
    AppSingleItemTopSection
  },

  props: {
    projectProp: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <app-subpage-header :title-prop="'Modules'" />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <module-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import ModuleSingleOverview from '@/components/module/ModuleSingleOverview'
import AppLoaderContent from '@/components/app/AppLoaderContent'

export default {
  name: "ModuleSingleView",
  components: {
    AppLoaderContent,
    ModuleSingleOverview,
    AppSubpageHeader
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'module/getCurrentState'
      }
    )
  },

  methods: {
    async getModuleInfo () {
      try {
        await this.$store.dispatch('module/fetchSingleModule', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/modules')
      }
    }
  },

  mounted () {
    this.getModuleInfo()
  }
}
</script>

<style scoped>

</style>

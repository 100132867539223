<template>
  <div>
    <catalogue-list-all-container />
  </div>
</template>

<script>
import CatalogueListAllContainer from '@/components/catalogue/CatalogueListAllContainer'

export default {
  name: "AllCataloguesView",
  components: { CatalogueListAllContainer },

  methods: {
    getLoginImageURL () {
      return require('@/assets/img/sandbox-logo.png')
    }
  }
}
</script>

<style scoped>

</style>

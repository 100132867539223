import VueCookies from 'vue-cookies'
import AuthService from '@/services/auth.service'

const state = () => ({
  user: null
})

const getters = {
  isAuthenticated () {
    let cookie = VueCookies.get('current_user')
    
    return cookie != null
  },
  
  getCurrentUser (state) {
    return state.user
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },
  
  signOut (state) {
    state.user = null
    VueCookies.remove('current_user', null, process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL)
  }
}

const actions = {
  async signOut (vuexContext) {
    vuexContext.commit('signOut')
  },
  
  async login (vuexContext, data) {
    const response = await AuthService.login(data)
    
    if (data.isRememberMe) {
      VueCookies.set('current_user', response.access_token, '1Y', null, process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL, false, 'Lax')
      vuexContext.commit('setUser', response.user)
      return
    }
    
    VueCookies.set('current_user', response.access_token, 0, null, process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL, false, 'Lax')
    vuexContext.commit('setUser', response.user)
  },
  
  async fetchUserIfMissing (vuexContext) {
    let currentUser = vuexContext.getters.getCurrentUser
    
    if (!(currentUser && currentUser.id)) {
      const response = await AuthService.getMe()
      vuexContext.commit('setUser', response)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
    install (Vue) {
        Vue.prototype.$timeToLocal = function (time) {
            try {
                // Ensure 'time' is a valid UTC time
                const utcTime = dayjs.utc(`${time}Z`)
                if (!utcTime.isValid()) {
                    throw new Error('Invalid UTC time')
                }

                // Convert UTC time to local time and format it
                const localTime = utcTime.local().format('YYYY-MM-DD HH:mm')
                return localTime
            } catch (error) {
                console.error('Error converting UTC to local time:', error)
                return '' // Handle the error gracefully, return an empty string, or other appropriate value.
            }
        }
    }
}

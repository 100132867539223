<template>
  <div class="mx-auto">
    <div class="image-component">
      <v-img
        v-if="imageProp"
        :class="imageCompOptions.class ? imageCompOptions.class : ''"
        :contain="imageCompOptions.contain"
        :cover="imageCompOptions.cover"
        :max-height="imageCompOptions.maxHeight"
        :src="imageProp.url"
      />

      <div v-else-if="!imageProp">
        <v-card
          :class="imageCompOptions.class ? imageCompOptions.class : ''"
          :contain="imageCompOptions.contain"
          :cover="imageCompOptions.cover"
          :max-height="imageCompOptions.maxHeight"
          class="empty-image-card d-flex justify-center align-center pa-6 flex-column"
        >
          <v-avatar size="80">
            <div class="icon">
              <app-svg
                :src="'~/assets/img/icons/camera.svg'"
                alt="icon"
                color="white"
                size="36"
              />
            </div>
          </v-avatar>

          <div class="text-center color-grey mt-5">
            No image available. <br>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppImageHolder',
  components: { AppSvg },

  props: {
    imgCoverProp: {
      type: Boolean,
      required: true
    },

    imageProp: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      imageCompOptions: {
        maxHeight: '350px',
        cover: this.imgCoverProp,
        contain: !this.imgCoverProp,
        class: 'image-border-radius-overview'
      },
      isUpdateImageDialogVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loader-container {
  width: 300px;
  height: 300px;
  padding: 50px;
}

.selector-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.empty-image-card {
  background: var(--v-grey-lighten2);

  .v-avatar {
    background: var(--v-grey-lighten1);
    transition: background $secondary-transition;
  }
}

.image-component {
  @media screen and (min-width: 1900px) {
    width: 250px
  }
}
</style>

<template>
  <v-footer app class="footer-menu" height="80">
    <v-row class="text-center">
      <v-col
        v-for="(item, i) in itemsProp"
        :id="'menu-link-mobile-' + i"
        :key="'key-' + i"
      >
        <router-link
          :class="item.class"
          :to="item.to"
          active-class="selected"
          class="menu-item"
        >
          <v-tooltip color="primary" top>
            <template v-slot:activator="{ on, attrs }">
              <div class="fill" v-bind="attrs" v-on="on" />
            </template>

            <span>{{ item.title }} </span>
          </v-tooltip>
        </router-link>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "TheDefaultLayoutWrapMobileFooterMenu",
  props: {
    itemsProp: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_footer.scss';

.active {
  color: #42b983;
}

.exact-active-link {
  color: #42b983;
  font-weight: bold;
}
</style>

import $axios from '@/plugins/axios'

 class ProductService {
  getProducts (slug) {
    const baseOmtURLWithSlug = `${ location.protocol }//${ slug }${ process.env.VUE_APP_API_OMT_BASE_URL }/api/v1`

    return $axios.get(`${ baseOmtURLWithSlug }/get-products`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProductService()
<template>
  <div>
    <v-card
      class="change-organization-card rounded-0"
      elevation="0"
      height="50px"
      style="margin-top: 60px;"
    >
      <div class="d-flex align-center text-h6 px-5 py-2">
        <div class="flex-grow-1 font-weight-bold organization-info fade">
          <div class="d-contents">
            <span v-if="!isTooltipVisible" class="list-item-name">
              Working on
              <span class="primary--text ">
                 {{ organizationProp.name }}
              </span>
            </span>

            <v-tooltip v-else-if="isTooltipVisible" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="list-item-name">
                  Working on
                  <span class="primary--text" v-bind="attrs" v-on="on">
                     {{ organizationProp.name }}
                  </span>
                </span>
              </template>
              <span> Working on {{ organizationProp.name }}</span>
            </v-tooltip>
          </div>

        </div>
        <v-spacer />

        <v-hover v-slot="{ hover }">
          <v-btn
            :class="hover ? 'primary white--text' : 'lighten-2 primary--text' "
            outlined
            rounded
            small
            @click="openChooseOrganizationDialog"
          >
            Change
          </v-btn>
        </v-hover>

      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'TheDefaultLayoutWrapAppChooseOrganizationCard',
  props: {
    organizationProp: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      isTooltipVisible: false
    }
  },

  methods: {
    getElementWidth (selector) {
      let selectedElement = this.$el.querySelector(`${ selector }`)
      let elementWidth = window.getComputedStyle(selectedElement).width

      return Number(elementWidth.split('px')[0])
    },

    checkIfTooltipIsVisible () {
      let listItemNameWidth = this.getElementWidth('.list-item-name')
      let containerWidth = this.getElementWidth('.organization-info')

      //+10px to show tooltip when there is fade effect
      return (listItemNameWidth + 10) >= containerWidth
    },

    onResize () {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
    },

    async openChooseOrganizationDialog () {
      await this.$store.dispatch('user/fetchAllOrganizations')
      await this.$store.dispatch('chooseOrganization/showSelectOrganizationDialog')
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.change-organization-card {
  background-color: var(--v-primary-lighten2);
  border-bottom: 1px solid var(--v-grey-lighten1);
  border-top: 1px solid var(--v-grey-lighten1);

  & .organization-info {
    overflow: hidden;
    white-space: nowrap;
  }

  .d-contents {
    display: inline-flex;
  }

  .list-item-name {
    color: var(--v-grey-darken2);
    width: unset !important;
    overflow: hidden;
    white-space: nowrap;

    .theme--light.v-btn {
      color: var(--v-grey-darken1);
    }
  }

  .fade {
    mask-image: linear-gradient(90deg, #000000 85%, transparent);
    -webkit-mask-image: linear-gradient(90deg, #000000 85%, transparent);
  }
}
</style>

<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-xl-row">
      <catalogue-single-overview-top-section-container />

      <catalogue-single-overview-main-section-container />
    </v-layout>
  </div>
</template>

<script>
import CatalogueSingleOverviewTopSectionContainer
  from '@/components/catalogue/single-overview/CatalogueSingleOverviewTopSectionContainer'
import CatalogueSingleOverviewMainSectionContainer
  from '@/components/catalogue/single-overview/CatalogueSingleOverviewMainSectionContainer'

export default {
  name: "CatalogueSingleOverview",
  components: {
    CatalogueSingleOverviewMainSectionContainer,
    CatalogueSingleOverviewTopSectionContainer
  }
}
</script>

<style scoped>

</style>

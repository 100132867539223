<template>
  <div>
    <v-layout class="d-flex align-center mb-10">
      <h2 class="text-h2">
        Projects
      </h2>

      <!--      <v-spacer />-->

      <app-data-sort-by
          class="mx-1"
          :sort-options-prop="sortOptions"
          @changeSort="makeSort"

      />
    </v-layout>

    <list-overview
      :can-create=false
      :cols-prop="{ cols: 6, xs: 6, sm: 4, md: 3, lg: 2, xl: 2 }"
      :data-source="projectsProp"
      :is-list-loading-prop="isListLoadingProp"
      :selectable="false"
      list-type="project"
      target-url="/projects"
    >
      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        />
      </template>
    </list-overview>
  </div>
</template>

<script>
import ListOverview from '@/components/list/ListOverview'
import ListItemCommon from '@/components/list/ListItemCommon'
import AppDataSortBy from '@/components/app/data/AppDataSortBy.vue'

import { v4 as uuidv4 } from "uuid"

export default {
  name: "ProjectListAll",
  components: { ListItemCommon, ListOverview, AppDataSortBy },
  props: {
    projectsProp: {
      type: Array,
      required: true
    },
    isListLoadingProp: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      sortOptions: [
        { text: 'Name - [A-Z]', key: 'name', direction: 'acs', id: uuidv4() },
        { text: 'Name - [Z-A]', key: 'name', direction: 'desc', id: uuidv4() },

        { text: 'Date Updated - [A-Z]', key: 'updatedAt', direction: 'acs',  id: uuidv4() },
        { text: 'Date Updated - [Z-A]', key: 'updatedAt', direction: 'desc',  id: uuidv4() },
      ],

      sortStrategy: [
        { key: 'name', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => a.name.localeCompare(b.name))
          }
        },

        { key: 'name', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => b.name.localeCompare(a.name))
          }
        },

        { key: 'updatedAt', direction: 'acs', sortFunc: (items) => {
            return items.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
          }
        },

        { key: 'updatedAt', direction: 'desc', sortFunc: (items) => {
            return items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          }
        }
      ]
    }
  },

  methods: {
    makeSort (sortParam) {
      const currentSortStrategy = this.sortStrategy.find(item => item.key === sortParam.key && item.direction === sortParam.direction)
      if (currentSortStrategy) {
        currentSortStrategy.sortFunc(this.projectsProp)
      }
    }
  }
}
</script>

<style scoped>

</style>

const state = {
  showSelectOrganizationDialog: false,
  selectOrganizationDialogKey: 0
}

const getters = {
  getShowSelectOrganizationDialog (state) {
    return state.showSelectOrganizationDialog
  },

  getSelectOrganizationDialogKey (state) {
    return state.selectOrganizationDialogKey
  }
}

const mutations = {
  setShowSelectOrganizationDialog (state, data) {
    state.showSelectOrganizationDialog = data
  },

  setSelectOrganizationDialogKey (state, data) {
    state.selectOrganizationDialogKey = data
  }
}

const actions = {
  showSelectOrganizationDialog (vuexContext) {
    vuexContext.commit('setSelectOrganizationDialogKey', vuexContext.getters.getSelectOrganizationDialogKey + 1)
    vuexContext.commit('setShowSelectOrganizationDialog', true)
  },

  toggleSelectOrganizationDialog (vuexContext, showDialog) {
    vuexContext.commit('setShowSelectOrganizationDialog', showDialog)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

<template>
  <!--            <div-->
  <!--              class="list-modules-categories d-flex align-center"-->
  <!--              @click="showAddCatalogueDialog"-->
  <!--            >-->
  <!--              <v-avatar size="40">-->
  <!--                <div class="icon">-->
  <!--                  <app-svg-->
  <!--                    alt="icon"-->
  <!--                    color="white"-->
  <!--                    size="19"-->
  <!--                    src="~/assets/img/icons/module.svg"-->
  <!--                  />-->
  <!--                </div>-->
  <!--              </v-avatar>-->

  <!--              <div class="section-title ml-5">-->
  <!--                Create module-->
  <!--              </div>-->
  <!--            </div>-->

  <!--            <v-divider style="margin-top: 10px;" />-->
  <div class="app-list-item hover">
    <v-avatar class="image-border cursor-pointer" rounded size="40">
      <v-img
        :alt="`${moduleProp.name} picture`"
        :src="moduleProp.image && moduleProp.image.url"
        @click="$router.push(`/modules/${moduleProp.id}`)"
      />
    </v-avatar>

    <div
      class="list-item-content cursor-pointer"
      @click="$router.push(`/modules/${moduleProp.id}`)"
    >
      <div class="content-section content-title">
        <div
          ref="testSubtitleModuleContainer"
          class="d-flex align-center text-subtitle-container fade"
          style="width: 100%;"
        >
          <div class="d-contents">
            <span
              v-if="!isTooltipVisible"
              ref="listItemModuleName"
              class="list-item-name"
            >
              {{ moduleProp.name }}
            </span>

            <v-tooltip v-else-if="isTooltipVisible" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  ref="listItemModuleName"
                  class="list-item-name"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ moduleProp.name }}
                </span>
              </template>
              <span> {{ moduleProp.name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="list-item-actions">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="module-inline-upload"
            icon
            small
            v-bind="attrs"
            v-on="on"
            @click.native="uploadModule(moduleProp.id)"
          >
            <app-svg
              color="grey darken-1"
              size="16"
              src="~/assets/img/icons/icon-plus.svg"
            />
          </v-btn>

        </template>
        <span> Add to module</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="module-inline-download"
            icon
            small
            v-bind="attrs"
            v-on="on"
            @click.native="downloadModule(moduleProp.id)"
          >
            <app-svg
              color="grey darken-1"
              size="16"
              src="~/assets/img/icons/import-grey.svg"
            />
          </v-btn>

        </template>
        <span> Download </span>
      </v-tooltip>

    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'
import VueCookies from 'vue-cookies'
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogueModuleListItem',
  components: { AppSvg },
  props: {
    hover: Boolean,
    moduleProp: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      isTooltipVisible: false
    }
  },
  computed: {
    ...mapGetters(
      {
        userSelectedOrganization: 'user/getUserSelectedOrganization'
      }
    )
  },

  methods: {
    getElementWidth (selector) {
      let selectedElement = this.$refs[selector]
      let elementWidth = window.getComputedStyle(selectedElement).width

      return Number(elementWidth.split('px')[0])
    },

    checkIfTooltipIsVisible () {
      let listItemNameWidth = this.getElementWidth('listItemModuleName')
      let containerWidth = this.getElementWidth('testSubtitleModuleContainer')

      //+10px to show tooltip when there is fade effect
      return (listItemNameWidth + 10) >= containerWidth
    },

    onResize () {
      this.isTooltipVisible = this.checkIfTooltipIsVisible()
    },

    downloadModule (moduleId) {
      this.$store.dispatch('catalogue/downloadOneCatalogueModule', moduleId)
    },

    async uploadModule (moduleId) {
      try {
        let token = VueCookies.get('current_user')
        const response = await window.jsSandboxInterface.uploadModuleEvent(moduleId, token, this.userSelectedOrganization.slug)
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully uploaded',
          duration: 4000,
          mode: 'success'
        })

        if (response != 'Success') {
          throw new Error()
        }
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to upload',
          duration: 4000,
          mode: 'warning'
        })
      }
    }
  },

  mounted () {
    setTimeout(() => {
      this.$nextTick(() => {
        this.isTooltipVisible = this.checkIfTooltipIsVisible()
        window.addEventListener('resize', this.onResize)
      })
    }, 100)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.app-list-item {
  position: relative;
  z-index: 1;
  outline: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  transition: background $secondary-transition;
  color: var(--v-grey-darken2);
  font-family: $body-font-family;
  font-size: 1rem;
  word-break: break-word;

  // default children spacing
  & > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .list-item-actions {
    display: inline-flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
    margin-right: 4px;

    & > * {
      margin-left: 10px;
    }
  }

  .list-item-content {
    display: flex;
    min-height: 40px;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;

    &:hover {
      text-decoration-line: underline
    }

    .content-section {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .content-subtitle {
      font-size: 0.75rem;
      color: var(--v-grey-darken1);
    }

    .content-title ~ .content-subtitle {
      margin-top: 5px;
    }
  }

  .d-contents {
    display: inline-grid;
  }

  .list-item-name {
    color: var(--v-grey-darken2);
    width: unset !important;
    overflow: hidden;
    white-space: nowrap;

    .theme--light.v-btn {
      color: var(--v-grey-darken1);
    }
  }

  .fade {
    mask-image: linear-gradient(90deg, #000000 80%, transparent);
    -webkit-mask-image: linear-gradient(90deg, #000000 80%, transparent);
  }
}

</style>


<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="isLoadingProp"
    :title-prop="'Delete View?'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="ml-4">
      <span>Are you sure?</span>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="6">
          <v-btn
            id="close-dialog-button"
            :disabled="isLoadingProp"
            block
            color="primary"
            outlined
            raised
            rounded
            small
            @click.prevent="hideDialog"
          >
            Close
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            id="submit-button"
            :disabled="isLoadingProp"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            small
            @click.native="removeCurrentView"
          >
            <span class="text-capitalize">
              Delete
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

export default {
  name: 'ProjectSingleOverviewDeleteBackgroundDialog',
  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.resetModule()
      this.isDialogVisible = false
    },

    resetModule () {
      this.module = null
    },

    removeCurrentView () {
      this.$emit('removeBackground')
    }
  }
}
</script>

<style lang="scss" scoped>
.color-light-gray {
  color: var(--v-grey-lighten1);
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import AppAuthLayoutContent from '@/AppAuthLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
import guest from '@/router/middleware/guest'
import auth from '@/router/middleware/auth'
import AppLayoutContent from '@/AppLayoutContent'
import AllCataloguesView from '@/views/AllCataloguesView'
import AllModulesView from '@/views/AllModulesView'
import AllProjectsView from '@/views/AllProjectsView'
import middlewarePipeline from '@/router/middlewarePipeline'
import CatalogueSingleView from '@/views/catalogue/CatalogueSingleView'
import ModuleSingleView from '@/views/module/ModuleSingleView'
import ProjectSingleView from '@/views/project/ProjectSingleView'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/catalogues'
      },

      {
        path: '/catalogues',
        component: AllCataloguesView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/catalogues/:id',
        component: CatalogueSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/modules',
        component: AllModulesView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/modules/:id',
        component: ModuleSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/projects',
        component: AllProjectsView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/projects/:id',
        component: ProjectSingleView,
        meta: {
          middleware: [auth]
        }
      }
    ]
  },

  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (Object.keys(to.query).length === 0) {
      return {
        x: 0,
        y: 0,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next }

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router

<template>
  <div>
    <app-subpage-header :title-prop="'Projects'" />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <project-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { actionStates } from '@/constants'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import ProjectSingleOverview from '@/components/project/ProjectSingleOverview'

export default {
  name: "ProjectSingleView",
  components: {
    ProjectSingleOverview,
    AppSubpageHeader,
    AppLoaderContent
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'project/getCurrentState'
      }
    )
  },

  methods: {
    async getProjectInfo () {
      try {
        await this.$store.dispatch('project/fetchSingleProject', this.$route.params.id)
      } catch (error) {
        await this.$router.replace('/projects')
      }
    }
  },

  mounted () {
    this.getProjectInfo()
  }
}
</script>

<style scoped>

</style>

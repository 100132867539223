<template>
  <div class="flex-grow-1" style="min-height: 400px;">
    <div class="tabs-content-section pa-2">
      <div v-if="currentState === actionStateConstants.DATA_LOADED">
        <module-single-overview-main-section
          :module-prop.sync="module"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { actionStates } from '@/constants'
import { mapGetters } from 'vuex'
import ModuleSingleOverviewMainSection from '@/components/module/single-overview/ModuleSingleOverviewMainSection'

export default {
  name: "ModuleSingleOverviewMainSectionContainer",
  components: {
    ModuleSingleOverviewMainSection
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'module/getCurrentState',
        module: 'module/getSingleModule'
      }
    )
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <v-app-bar app clipped-left elevation="0" height="60px" color="#FFFFFF">

      <v-img
        id="home-logo-button"
        :aspect-ratio="3.25"
        :src="getLogoImage()"
        contain
        max-height="35"
        max-width="140"
        style="cursor:pointer;"
        v-bind="$attrs"
        v-on="$listeners"
        @click.stop="redirectToHomePage"
      />

      <v-spacer />

      <div class="d-flex align-center">
        <v-tooltip bottom content-class="tooltip-background-primary-lighten3">
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn
                  :class="hover ? 'background-primary-lighten3' : 'background-grey-lighten4' "
                  class="mr-3"
                  color="grey darken-1"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  rounded
                  fill
                  small
                  fab
                  @click="reload"
              >
                <app-svg
                    size="16"
                    src="~/assets/img/icons/icon-refresh.svg"
                />
              </v-btn>
            </v-hover>
          </template>
          <span>Reload Page</span>
        </v-tooltip>

        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-tooltip
                bottom
                content-class="tooltip-background-primary-lighten3"
            >
              <template #activator="data" >

                <div>
                  <v-btn
                      id="bar-account-button"
                      class="mx-2"
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-avatar
                        color="primary"
                        size="30"
                        v-bind="data.attrs"
                        v-on="data.on"
                    >
                      <img :src="getUserAvatarImage" alt="profile-avatar-image" />
                    </v-avatar>
                  </v-btn>
                </div>
              </template>
              <span
                  v-if=" currentUser && currentUser.name"
                  id="tooltip-user-name"
              >
              {{ currentUser.name }}
            </span>
            </v-tooltip>
          </template>

          <v-list>
            <v-list-item id="menu-item-sign-out" link @click.native="logout()">
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>

    <the-default-layout-wrap-app-choose-organization-card
      v-if="selectedOrganization"
      :organization-prop="selectedOrganization"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import TheDefaultLayoutWrapAppChooseOrganizationCard from '@/layouts/TheDefaultLayoutWrapAppChooseOrganizationCard'
import AppSvg from "@/components/app/AppSvg.vue";

export default {
  name: 'TheDefaultLayoutWrapAppBar',
  components: {AppSvg, TheDefaultLayoutWrapAppChooseOrganizationCard },
  computed: {
    ...mapGetters(
      {
        selectedOrganization: 'user/getUserSelectedOrganization',
        currentUser: 'auth/getCurrentUser'
      }
    ),

    getUserAvatarImage () {
      return require('@/assets/img/profile-icon-header.png')
    }
  },

  methods: {
    getLogoImage () {
      return require('@/assets/img/sandbox-logo.png')
    },

    goToLoginPath () {
      window.location.href = `${ window.location.protocol }//${ process.env.VUE_APP_PLUGIN_FRONT_END_BASE_URL }:${ window.location.port }/login`
    },

    reload () {
      window.location.reload()
    },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.goToLoginPath()
    },

    redirectToHomePage () {
      if (this.$route.path === '/catalogues') {
        return
      }

      this.$router.push(`/catalogues`)
    }
  }
}
</script>

<!--suppress CssInvalidPseudoSelector -->
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

:deep(.v-toolbar__content) {
  padding: 15px 25px;
}
</style>

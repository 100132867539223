import $axios from '../plugins/axios'

class ModuleService {
  getAllModules () {
    return $axios.get(`/get-modules`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getSingleModule (moduleId) {
    return $axios.get(`/get-module/${ moduleId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  downloadOneModule (moduleId) {
    return $axios.get(`/get-module-revit-files/${ moduleId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  downloadAllModules (moduleId) {
    return $axios.get(`/get-module-modules-revit-files/${ moduleId }`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ModuleService()

<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      :class="{ 'px-2': hover }"
      class="back-btn px-0"
      color="grey darken-1"
      height="36"
      min-width="36"
      outlined
      rounded
      v-bind="$attrs"
      v-on="$listeners"
    >
      <app-svg
        class="mx-1"
        color="grey darken-1"
        size="15"
        src="~/assets/img/icons/arrow-back.svg"
      />
      <v-expand-x-transition>
        <div v-if="hover" class="txt">
          <span class="ml-2">{{ labelProp }}</span>
        </div>
      </v-expand-x-transition>
    </v-btn>
  </v-hover>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'

export default {
  name: 'AppButtonBack',
  components: { AppSvg },

  props: {
    labelProp: {
      type: String,
      default: 'Back'
    }
  }
}
</script>

<style lang="scss" scoped>
.back-btn.v-btn--active:hover::before,
.back-btn.v-btn--active::before {
  opacity: 0 !important;
}
</style>


export default {
  install (Vue) {
    Vue.prototype.$isFeatureAvailable = function (featureString) {
      //
      const organizationSelectedFeatures = this.$store.getters['user/getOrganizationSelectedFeatures']

      const featuresMap  = [
        { name: 'project-configurations-tab', internal_id: '52c8dd31-d86c-4293-a95f-1d954664dd36' },

        { name: 'project-background-tab', internal_id: '357053f6-9209-40d0-a26a-43bd5ec828ce' },

        { name: 'project-grid-tab', internal_id: 'c2a386b4-8be3-4160-87d0-22465c44c3fa' },

        { name: 'project-rule-model-tab', internal_id: '810f39f6-afa0-4d3c-9a9a-3646167c6608' },

        { name: 'project-configurations-tab-each-conf-download-text-btn', internal_id: 'b50af478-c6c3-4115-98db-a30cce299f2e' },
      ]

      const currentFeatureInternalId = featuresMap.find(
        item => item.name === featureString
      ).internal_id

      const currentSelectedFeature = organizationSelectedFeatures.find(item => item.internal_id === currentFeatureInternalId)

      if (currentFeatureInternalId && currentSelectedFeature) {
        return currentSelectedFeature.enabled
      }
      return false
    }
  }
}
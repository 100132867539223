<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-xl-row">
      <module-single-overview-top-section-container />

      <module-single-overview-main-section-container />
    </v-layout>
  </div>
</template>

<script>
import ModuleSingleOverviewTopSectionContainer
  from '@/components/module/single-overview/ModuleSingleOverviewTopSectionContainer'
import ModuleSingleOverviewMainSectionContainer
  from '@/components/module/single-overview/ModuleSingleOverviewMainSectionContainer'

export default {
  name: "ModuleSingleOverview",
  components: {
    ModuleSingleOverviewMainSectionContainer,
    ModuleSingleOverviewTopSectionContainer
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-expansion-panels
      v-for="(configuration, index) in configurationsProp"
      :key="`configuration-${configuration.id}-${index}`"
      accordion
      flat
      focusable
      hover
      multiple
      tile
    >
      <app-expansion-panel fit>
        <template #header>
          <h5 class="text-h5 py-0">
            {{ configuration.name }}
          </h5>
        </template>

        <div>
          <div class="app-list-item ma-2 hover">
            <div class="d-flex flex-column">
              <v-img
                :alt="`${configuration.name} picture`"
                :src="configuration.image && configuration.image.url"
                class="image-border mb-2"
                height="140px"
                style="border-radius: 6px; flex: 0 0 auto;"
                width="275px"
              />

              <div class="content-section content-title">
                <div style="margin-right: auto;">
                  <div class="d-flex flex-column text-subtitle-2">
                  <span class="list-item-name">
                    <b> Created by: </b>
                    {{ configuration.created_by_user.name }} / {{ configuration.created_by_user.email }}
                  </span>

                    <span class="list-item-name">
                    <b> Date Created: </b> {{ configuration.created_at }}
                  </span>

                    <span class="list-item-name">
                    <b> Last Updated: </b> {{ configuration.updated_at }}
                  </span>

                    <span class="list-item-name">
                    <b> Number of Modules: </b>
                    <template v-if="configuration.latest_save && configuration.latest_save.modules > 0">
                      {{ configuration.latest_save.modules }}
                    </template>

                    <template v-else-if="!(configuration.latest_save && configuration.latest_save.modules > 0)">
                      0
                    </template>
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="configuration.latest_save" class="list-item-actions mt-5 mb-2">
              <v-btn
                class="download-configuration-save-button"
                height="30px"
                rounded
                small
                text
                @click.native="$emit('downloadConfigurationSave', configuration.latest_save.id)"
              >
                <div class="guttered-row">
                  <img
                    alt="download-configuration-summary-icon"
                    class="mr-2"
                    src="@/assets/img/icons/icon-download-line.png"
                    width="16"
                  />
                  <span>Download</span>
                </div>
              </v-btn>

              <v-btn
                v-if="configuration.latest_save && $isFeatureAvailable('project-configurations-tab-each-conf-download-text-btn')"
                class="get-configuration-save-button"
                height="30px"
                rounded
                small
                text
                @click="getConfigurationSaveDbaTextFile(configuration, configuration.latest_save)"
              >
                <div class="guttered-row">
                  <img
                    alt="download-configuration-txt-icon"
                    class="mr-2"
                    src="@/assets/img/icons/icon-download-line.png"
                    width="16"
                  />
                  <span>Download (Text)</span>
                </div>
              </v-btn>
            </div>
          </div>
        </div>
      </app-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import AppExpansionPanel from '@/components/app/AppExpansionPanel'
import ConfigurationService from '@/services/configuration.service'

export default {
  name: 'ProjectSingleOverviewMainSectionConfigurationsTab',
  components: { AppExpansionPanel },
  props: {
    projectProp: {
      type: Object,
      default: () => {
      }
    },

    configurationsProp: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    async getConfigurationSaveDbaTextFile (configuration, configurationSave) {
      try {
        let saveDbaText = await ConfigurationService.getConfigurationSaveDbaTextFile(configurationSave.id)
        const response = await window.jsSandboxInterface.downloadConfigurationSaveDbaTextEvent(
          saveDbaText,
          this.projectProp.name,
          configuration.name,
          configurationSave.version,
          configurationSave.created_at
        )

        if (response != 'Success') {
          throw new Error()
        }

        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Successfully download the file',
          duration: 4000,
          mode: 'success'
        })
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Failed to download the file',
          duration: 4000,
          mode: 'warning'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
